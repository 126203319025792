
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {Emit, Prop} from "vue-property-decorator";
import {Product, TimeTypes} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";

Component.registerHooks(['validations'])
@Component({
  components: {SygniContainerTitle, SygniRoundedButton, SygniCard},
})
export default class ProductCard extends Vue {
  @Prop() product!: Product | any;
  @Prop() company!: any;
  @Prop({default: false}) authorized!: boolean;
  @Emit('acceptProduct') onAcceptProductClick(){
    return this.product;
  }
  TimeTypes = TimeTypes

  get productDataName() {
    return 'data';
    // return this.product.type === 'LOAN' ? 'loanData' : 'billAgreementData';
  }
}
