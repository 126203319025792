var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.product.id ? _c('sygni-card', {
    staticClass: "product-card"
  }, [_c('div', {
    staticClass: "card__group card__group--company"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": _vm.company.logo
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.company.name) + " ")]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.company.description) + " ")]), _c('div', {
    staticClass: "card__empty-div"
  }), _c('sygni-rounded-button', {
    staticClass: "outline disabled read-more-button"
  }, [_vm._v(" Read more... ")])], 1), _c('div', {
    staticClass: "card__group  card__group--product"
  }, [_c('div', {
    staticClass: "product__name"
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")]), _c('sygni-container-title', [_vm._v(" " + _vm._s(_vm.product.code) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" INVESTMENT VALUE ")]), _vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.product.investmentValue, 2)) + " "), _c('span', {
    staticClass: "grey"
  }, [_vm._v(" PLN ")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm.product[_vm.productDataName].timeType === _vm.TimeTypes.duration ? [_c('div', {
    staticClass: "label"
  }, [_vm._v(" DURATION ")]), _vm._v(" " + _vm._s(_vm.product[_vm.productDataName].duration.count) + " "), _c('span', {
    staticClass: "grey"
  }, [_vm._v(" " + _vm._s(_vm.product[_vm.productDataName].duration.type) + " ")])] : _vm._e(), _vm.product[_vm.productDataName].timeType === _vm.TimeTypes.fixedRepaymentDate ? [_c('div', {
    staticClass: "label"
  }, [_vm._v(" REPAYMENT DATE ")]), _vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(_vm.product[_vm.productDataName].fixedRepaymentDate)) + " ")] : _vm._e()], 2)]), _c('div', {
    staticClass: "row"
  }, [_vm.product[_vm.productDataName].advancedOptions ? [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" COMMISSION P.A. ")]), _vm._v(" " + _vm._s(_vm.product[_vm.productDataName].advancedOptions.commission) + " "), _c('span', {
    staticClass: "grey"
  }, [_vm._v(" " + _vm._s(_vm.product[_vm.productDataName].advancedOptions.commissionType) + " ")])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" PAYMENT ")]), _vm._v(" " + _vm._s(_vm.$t(_vm.product[_vm.productDataName].advancedOptions.commissionPayment)) + " ")])] : _vm._e(), !_vm.product[_vm.productDataName].advancedOptions ? [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" INTEREST P.A. ")]), _vm._v(" " + _vm._s(_vm.product[_vm.productDataName].simpleOptions.interest) + " "), _c('span', {
    staticClass: "grey"
  }, [_vm._v(" % ")])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" INTEREST PAYMENT ")]), _vm._v(" " + _vm._s(_vm.$t(_vm.product[_vm.productDataName].simpleOptions.interestPayment)) + " ")])] : _vm._e()], 2), _vm.product[_vm.productDataName].advancedOptions ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" MARGIN ")]), _vm._v(" " + _vm._s(_vm.product[_vm.productDataName].advancedOptions.margin) + " "), _c('span', {
    staticClass: "grey"
  }, [_vm._v(" % ")])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" PAYMENT ")]), _vm._v(" " + _vm._s(_vm.$t(_vm.product[_vm.productDataName].advancedOptions.marginPayment)) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "card__empty-div"
  }), !_vm.authorized ? _c('sygni-rounded-button', {
    staticClass: "outline inactive",
    attrs: {
      "disabled": true
    }
  }, [_vm._v(" Awaiting for data authorize ")]) : _vm._e(), _vm.authorized ? _c('sygni-rounded-button', {
    staticClass: "filled red",
    on: {
      "click": _vm.onAcceptProductClick
    }
  }, [_vm._v(" Review the offer ")]) : _vm._e()], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }